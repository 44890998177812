import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import ContactForm from "../Global/ContactForm"

const FormToggle = ({ open, setOpen }) => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setScroll(
        window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 200
      )
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <button
      type="button"
      className={` fixed flex items-center bottom-6 right-6 gap-2 text-white text-[14px] leading-[24px] font-medium bg-[#232323] rounded-[180px] py-3 px-6 z-20 form-toggle ${
        scroll ? "hide" : ""
      }`}
      onClick={() => setOpen(!open)}
    >
      Enquire now
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M3.333 8h9.333m0 0L8 3.335m4.666 4.667L8 12.667"
        ></path>
      </svg>
    </button>
  )
}

const Overlay = ({ open, setOpen }) => {
  return (
    <div
      className={`panel-nav--overlay ${open && "open"}`}
      onClick={() => setOpen(!open)}
      aria-hidden="true"
    ></div>
  )
}

const OverlayForm = () => {
  const [open, setOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      sanitySiteSettings {
        phone
        email
        instagramHandle
        newsletter
        navContactFormTitle
        _rawAddress
      }
    }
  `)

  return (
    <>
      <div
        className={
          `panel-nav panel-nav--project overflow-y-scroll h-screen ` +
          (open && " open")
        }
        open={open}
      >
        {data.sanitySiteSettings.navContactFormTitle && (
          <h3 className="text-[24px] leading-[1.4] font-serif mb-10">
            {data.sanitySiteSettings.navContactFormTitle}
          </h3>
        )}
        <ContactForm />
        <button
          className="absolute right-5 md:right-8"
          open={open}
          onClick={() => setOpen(!open)}
        >
          <span className="sr-only">Close</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path stroke="#000" d="M1.188 1.146l18 18m-18.708 0l18-18"></path>
          </svg>
        </button>
      </div>
      <FormToggle open={open} setOpen={setOpen} />
      <Overlay open={open} setOpen={setOpen} />
    </>
  )
}

export default OverlayForm
